<template>
  <v-app>
    <div
      style="height: 100%;display: flex;justify-content: center;align-items: center;padding: 10px"
      :style="{backgroundColor: this.$wikaz.getInstance().theme.colors.backgroundlogin}"
    >
      <fx-formulaire-generique
        v-if="inscription"
        style="height: 500px"
        :url-ressource="'api/wikaz/invitation/create?id='+$route.params.id+'&email=' + $route.query.email"
        @on-submit="f_add"
      />
      <v-card
        v-else
        class="elevation-8"
        style="width: 400px"
      >
        <v-toolbar
          color="#456173"
          dense
        >
          <v-toolbar-title style="color: white">
            {{ reponse.titre }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>{{ reponse.message }}</v-card-text>
      </v-card>
    </div>
  </v-app>
</template>
<script>
export default {
    name: 'VxInscription',
    data () {
        return {
            reponse: {},
            inscription: true,
            loginDetails: {
                nom: '',
                prenom: '',
                email: this.$route.query.email,
                password: '',
                repassword: '',
            },
            valid: true,
            emailRules: [
                v => !! v || 'E-mail est requis',
                v => /.+@.+\..+/.test(v) || 'E-mail non valide',
            ],
            loading: false,
            checkbox: false,
            showPassword1: false,
            showPassword2: false,
        }
    },
    methods: {
        f_add (data) {
            if (data.mdp1 === data.mdp2) {
                this.$wikaz.request.post('api/wikaz/invitation?id=' + this.$route.params.id, data).then((data) => {
                    if (data.code && data.code === 'info') {
                        this.reponse = data
                        this.inscription = false
                    } else {
                        this.$wikaz.outils.notification.showAuto(data)
                    }
                })
            }
        },
        createPost () {
            if (this.$refs.loginDetails.validate() && (this.loginDetails.champ2 === this.loginDetails.champ3)) {
                this.loading = true
                this.$wikaz.request.post('api/wikaz/invitation?id=' + this.$route.params.id, this.loginDetails).then((data) => {
                    if (data.code && data.code === 'info') {
                        this.reponse = data
                        this.loginDetails = {
                            nom: '',
                            prenom: '',
                            email: '',
                            password: '',
                            repassword: '',
                        }
                        this.checkbox = false
                        this.inscription = false
                    } else {
                        this.$wikaz.outils.notification.showAuto(data)
                    }
                    this.loading = false
                })
            } else {
                this.$wikaz.outils.notification.showWarning('Merci de vérifier votre saisie!', 5.5)
            }
        },
    },
}
</script>